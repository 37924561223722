import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function generateSlug(fileKey: string): string {
  return fileKey
    .split(".")[0]
    .toLowerCase()
    .replace(/[^a-z0-9]+/g, "-")
    .replace(/(^-|-$)/g, "");
}

export function checkEnv(env: NodeJS.ProcessEnv) {
  const required = [
    "REPLICA_URL",
    "LITESTREAM_ACCESS_KEY_ID",
    "LITESTREAM_SECRET_ACCESS_KEY",
    "R2_PUBLIC_URL",
    "DOMAIN_URL",
    "BETTER_AUTH_SECRET",
    "BETTER_AUTH_URL",
    "ENCRYPTION_KEY",
    "SENDKIT_API_KEY",
    "AWS_REGION",
    "AWS_ACCESS_KEY_ID",
    "AWS_SECRET_ACCESS_KEY",
    "AWS_SES_CONFIGURATION_SET",
  ];

  const missing = required.filter((key) => !env[key]);

  if (missing.length > 0) {
    throw new Error(
      `Missing required environment variables: ${missing.join(", ")}`
    );
  }
}
